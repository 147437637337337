export const environment = {
  production: true,
  name: 'test',
  version: 'b9f64a7afa223fb17f231552351f843c38d02b60',
  oidcProvider: 'oidc.bexio-test',
  sentryDsn: 'https://865c88886ae64a588016320c95405f51@o1347949.ingest.sentry.io/4504312944525312',
  api_url: 'https://europe-west6-edelwise-test.cloudfunctions.net',
  firebase: {
    projectId: 'edelwise-test',
    appId: "1:41315570219:web:7cbfb4c3d854507523d06e",
    storageBucket: "edelwise-test.appspot.com",
    locationId: 'europe-west6',
    apiKey: 'AIzaSyCq5kpnwaaoglbj_2-bWbhtW-nafO5y7Cc',
    authDomain: "edelwise-test.firebaseapp.com",
    messagingSenderId: "41315570219",
    measurementId: "G-RBKVTQF0CP"
  },
  posthog: {
    enabled: false,
    apiKey: 'none',
    apiHost: 'none',
  },
  features: {
    csvExport: true,
    valuation: true,
  }
};
